<template>
    <div class="home">
        <div class="item first">
            <div class="box">
                <div class="left">
                    <h1>为摆脱传统而生</h1>
                    <p>一款全平台广告聚合框架</p>
                    <div class="btn btn-now" @click="$router.push({name:'docs'})">马上接入</div>
                </div>
                <div class="right">
                    <div class="logo"></div>
                </div>
            </div>
            <div class="bg">
                <svg viewBox="0 0 120 28">
                    <defs>
                        <filter id="goo">
                            <feGaussianBlur in="SourceGraphic" stdDeviation="1" result="blur" />
                            <feColorMatrix
                                in="blur"
                                mode="matrix"
                                values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 13 -9"
                                result="goo"
                            />
                            <xfeBlend in="SourceGraphic" in2="goo" />
                        </filter>
                        <path
                            id="wave"
                            d="M 0,10 C 30,10 30,15 60,15 90,15 90,10 120,10 150,10 150,15 180,15 210,15 210,10 240,10 v 28 h -240 z"
                        />
                    </defs>

                    <use id="wave3" class="wave" xlink:href="#wave" x="0" y="-2"></use>
                    <use id="wave2" class="wave" xlink:href="#wave" x="0" y="0"></use>

                    <g class="gooeff" filter="url(#goo)">
                        <circle class="drop drop1" cx="20" cy="2" r="8.8" />
                        <circle class="drop drop2" cx="25" cy="2.5" r="7.5" />
                        <circle class="drop drop3" cx="16" cy="2.8" r="9.2" />
                        <circle class="drop drop4" cx="18" cy="2" r="8.8" />
                        <circle class="drop drop5" cx="22" cy="2.5" r="7.5" />
                        <circle class="drop drop6" cx="26" cy="2.8" r="9.2" />
                        <circle class="drop drop1" cx="5" cy="4.4" r="8.8" />
                        <circle class="drop drop2" cx="5" cy="4.1" r="7.5" />
                        <circle class="drop drop3" cx="8" cy="3.8" r="9.2" />
                        <circle class="drop drop4" cx="3" cy="4.4" r="8.8" />
                        <circle class="drop drop5" cx="7" cy="4.1" r="7.5" />
                        <circle class="drop drop6" cx="10" cy="4.3" r="9.2" />

                        <circle class="drop drop1" cx="1.2" cy="5.4" r="8.8" />
                        <circle class="drop drop2" cx="5.2" cy="5.1" r="7.5" />
                        <circle class="drop drop3" cx="10.2" cy="5.3" r="9.2" />
                        <circle class="drop drop4" cx="3.2" cy="5.4" r="8.8" />
                        <circle class="drop drop5" cx="14.2" cy="5.1" r="7.5" />
                        <circle class="drop drop6" cx="17.2" cy="4.8" r="9.2" />
                        <use id="wave1" class="wave" xlink:href="#wave" x="0" y="1" />
                    </g>
                </svg>
            </div>
        </div>
        <div class="item second">
            <div class="box">
                <h2>我们的平台的目标</h2>
                <ul>
                    <li>
                        <div class="value">1000<span>+</span></div>
                        <div class="label">月活跃app数量</div>
                    </li>
                    <li>
                        <div class="value">150%<span>+</span></div>
                        <div class="label">广告平台收益</div>
                    </li>
                    <li>
                        <div class="value">1000W+</div>
                        <div class="label">日活跃用户</div>
                    </li>
                    <li>
                        <div class="value">N+</div>
                        <div class="label">广告平台</div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="item third">
            <div class="box">
                <ul>
                    <li>
                        <div class="title">自动化接入</div>
                        <div class="sub-title">开关平台一键搞定</div>
                        <p class="content">
                            使用了插件集成技术，简单控制开关即可完成广告接入， 而且没有任何冗余代码
                        </p>
                        <div class="icon"></div>
                    </li>
                    <li>
                        <div class="title">控制体系统</div>
                        <div class="sub-title">支持云上精确控制广告</div>
                        <p class="content">
                            我们的控制体后台，允许通过在线配置，精确到某个机型、国家、系统、渠道来控制广告。
                        </p>
                        <div class="icon"></div>
                    </li>
                    <li>
                        <div class="title">优质的接口</div>
                        <div class="sub-title">尽全力给您舒适的使用体验</div>
                        <p class="content">
                            我们非常注重用户的使用体验，对每个接口的设计都精益求精
                        </p>
                        <div class="icon"></div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Home"
    };
</script>

<style lang="less" scoped>
    .home {
        width: 100%;
        ul {
            list-style: none;
            padding: 0;
            margin: 0;
        }
        .item {
            .box {
                width: 1200px;
                margin: 0 auto;
            }
            &.first {
                background: #edeef3;
                position: relative;
                .box {
                    position: relative;
                    z-index: 1;
                    height: 400px;
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    .left {
                        text-align: center;
                        p {
                            font-size: 18px;
                            color: #333;
                        }
                        .btn {
                            text-align: center;
                            display: inline-block;
                            padding: 0.3em 1.5em;
                            border-radius: 1em;
                            font-size: 14px;
                            margin: 0 15px;
                            cursor: pointer;
                            &.btn-now {
                                background: #3d65ff;
                                color: #fff;
                            }
                        }
                    }
                    .right {
                        .logo {
                            width: 250px;
                            height: 250px;
                            background-image: url("../../assets/img/logo-1024.png");
                            background-size: 250px;
                            background-repeat: no-repeat;
                            background-position: center;
                        }
                    }
                }
                .bg {
                    position: absolute;
                    top: 25%;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    overflow: hidden;
                    font-family: Lato, sans-serif;
                    --col-deepblue: #4478e3;
                    div {
                        background-color: var(--col-deepblue);
                        margin: -5px 0px 0px 0px;
                        padding: 0px;
                        color: #fff;
                        text-align: center;
                    }
                    svg {
                        width: 100%;
                        overflow: visible;
                    }

                    .wave {
                        animation: wave 3s linear;
                        animation-iteration-count: infinite;
                        fill: var(--col-deepblue);
                    }
                    .drop {
                        fill: var(--col-deepblue);
                        animation: drop 3.2s linear infinite normal;
                        stroke: var(--col-deepblue);
                        stroke-width: 0.5;
                        transform: translateY(25px);
                        transform-box: fill-box;
                        transform-origin: 50% 100%;
                    }
                    .drop2 {
                        animation-delay: 3s;
                        animation-duration: 3s;
                    }
                    .drop3 {
                        animation-delay: -2s;
                        animation-duration: 3.4s;
                    }
                    .drop4 {
                        animation-delay: 1.7s;
                    }
                    .drop5 {
                        animation-delay: 2.7s;
                        animation-duration: 3.1s;
                    }
                    .drop6 {
                        animation-delay: -2.1s;
                        animation-duration: 3.2s;
                    }
                    .gooeff {
                        filter: url(#goo);
                    }
                    #wave2 {
                        animation-duration: 5s;
                        animation-direction: reverse;
                        opacity: 0.6;
                    }
                    #wave3 {
                        animation-duration: 7s;
                        opacity: 0.3;
                    }
                    @keyframes drop {
                        0% {
                            transform: translateY(25px);
                        }
                        30% {
                            transform: translateY(-10px) scale(0.1);
                        }
                        30.001% {
                            transform: translateY(25px) scale(1);
                        }
                        70% {
                            transform: translateY(25px);
                        }
                        100% {
                            transform: translateY(-10px) scale(0.1);
                        }
                    }
                    @keyframes wave {
                        to {
                            transform: translateX(-100%);
                        }
                    }
                    @keyframes ball {
                        to {
                            transform: translateY(20%);
                        }
                    }
                }
            }
            &.second {
                background: #4478e3;
                color: #fff;
                position: relative;
                padding: 1px;
                //用transform就流畅了
                @keyframes heart {
                    from {
                        transform: translate(0, 0);
                    }
                    to {
                        transform: translate(-10px, 20px);
                    }
                }
                &::before,
                &::after {
                    content: "";
                    position: absolute;
                    width: 200px;
                    height: 200px;
                    background: #365fe4;
                    border-radius: 50%;
                }
                &::before {
                    top: -50px;
                    left: -80px;
                    animation: heart 2.5s ease-in-out 1s infinite alternate;
                }
                &::after {
                    bottom: -40px;
                    right: -80px;
                    animation: heart 1.3s ease-in-out 2.7s infinite alternate;
                }
                .box {
                    height: 300px;
                    position: relative;
                    &::before,
                    &::after {
                        content: "";
                        position: absolute;
                        width: 200px;
                        height: 200px;
                        background: #365fe4;
                        border-radius: 50%;
                    }
                    &::before {
                        bottom: 15%;
                        left: -5%;
                        animation: heart 1.3s ease-in-out 8s infinite alternate;
                    }
                    &::after {
                        top: -150px;
                        right: -10%;
                        animation: heart 1.3s ease-in-out 5s infinite alternate;
                    }
                    h2 {
                        text-align: center;
                        margin-top: 0;
                    }
                    ul {
                        display: flex;
                        align-items: flex-start;
                        justify-content: center;
                        li {
                            text-align: center;
                            margin: 0 40px;
                            .value {
                                font-size: 30px;
                                font-weight: 700;
                                margin: 20px 0;
                            }
                            .label {
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
            &.third {
                position: relative;
                z-index: 2;
                background: #fafafa;
                .box {
                    ul {
                        position: relative;
                        top: -80px;
                        display: flex;
                        align-items: flex-start;
                        justify-content: space-between;
                        li {
                            display: flex;
                            flex-direction: column;
                            width: 380px;
                            height: 500px;
                            background: #fff;
                            border-radius: 4px;
                            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
                            padding: 40px 30px;
                            text-align: center;
                            box-sizing: border-box;
                            .title {
                                font-size: 20px;
                                font-weight: 700;
                            }
                            .sub-title {
                                position: relative;
                                font-size: 16px;
                                color: #999;
                                margin: 5px 0 40px;
                                &::after {
                                    content: "";
                                    position: absolute;
                                    bottom: -15px;
                                    left: 50%;
                                    transform: translateX(-50%);
                                    width: 35px;
                                    height: 3px;
                                    border-radius: 3px;
                                    background: #3d65ff;
                                }
                            }
                            .content {
                                font-size: 12px;
                                color: #555;
                            }
                        }
                    }
                }
            }
        }
    }
</style>
