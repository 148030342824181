<template>
    <div class="layout">
        <MyHeader />
        <div class="main">
            <router-view />
        </div>
        <MyFooter />
    </div>
</template>

<script>
    import MyHeader from "./MyHeader";
    import MyFooter from "./MyFooter";
    export default {
        name: "MainLayout",
        components: {
            MyHeader,
            MyFooter
        }
    };
</script>
<style lang="less" scoped>
    .layout {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        .main {
            flex: 1;
        }
    }
</style>
