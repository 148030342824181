<template>
    <header>
        <div class="content">
            <div class="left">
                <i class="logo"></i>
                AppSdk
            </div>
            <div class="right">
                <ul class="navs">
                    <li class="nav-item" :class="{ active: $route.name === 'home' }">
                        <router-link :to="{ name: 'home' }">首页</router-link>
                    </li>
                    <!-- <li class="nav-item" :class="{ active: $route.name === 'docs' }">
                        <router-link :to="{ name: 'docs' }">文档</router-link>
                    </li> -->
                      <li class="nav-item" :class="{ active: $route.name === 'apps' }">
                        <router-link :to="{ name: 'apps' }">应用</router-link>
                      </li>
                </ul>
                <div class="btns">
                    <div class="btn btn-login">
                        <a href="http://console.njxing.cn/" target="_blank">登录开发者平台</a>
                    </div>
                    <div class="btn btn-register">注册</div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
    export default {
        name: "MyHeader"
    };
</script>

<style lang="less" scoped>
    header {
        position: sticky;
        z-index: 99;
        top: 0;
        height: 50px;
        width: 100%;
        background: #fff;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        .content {
            width: 1200px;
            height: 100%;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .left {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 24px;
                font-weight: 700;
                .logo {
                    display: block;
                    width: 32px;
                    height: 32px;
                    background-image: url("../../assets/img/logo.png");
                    background-size: 32px 32px;
                    background-position: center;
                    margin-right: 10px;
                }
            }
            .right {
                height: 100%;
                display: flex;
                align-items: flex-end;
                justify-content: center;
                .navs {
                    display: flex;
                    list-style: none;
                    padding: 0;
                    margin: 0;
                    height: 100%;
                    .nav-item {
                        min-width: 60px;
                        height: 100%;
                        font-size: 14px;
                        text-align: center;
                        line-height: 50px;
                        position: relative;
                        &::after {
                            position: absolute;
                            content: "";
                            bottom: 0;
                            left: 50%;
                            transform: translateX(-50%);
                            width: 0;
                            height: 3px;
                            background: #3d66ff;
                            transition: width 0.3s;
                        }
                        &.active {
                            &::after {
                                width: 100%;
                            }
                        }
                        a {
                            color: #666;
                            text-decoration: none;
                        }
                    }
                }
                .btns {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 100%;
                    margin-left: 50px;
                    .btn {
                        padding: 0.3em 1.5em;
                        border-radius: 1em;
                        font-size: 14px;
                        margin: 0 15px;
                        cursor: pointer;
                        a {
                            text-decoration: none;
                            color: inherit;
                        }
                        &.btn-login {
                            background: #e7edfe;
                            color: #3d65ff;
                        }
                        &.btn-register {
                            background: #3d65ff;
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
</style>
