import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "./assets/css/index.less";

Vue.config.productionTip = false;

import hljs from "highlight.js";
Vue.directive("highlight", function(el) {
    let blocks = el.querySelectorAll("pre code");
    blocks.forEach(block => {
        hljs.highlightBlock(block);
    });
});

new Vue({
    router,
    render: h => h(App)
}).$mount("#app");
