<template>
    <div id="app">
        <Layout />
    </div>
</template>
<script>
    import Layout from "./layouts/main/Index";
    export default {
        name: "App",
        components: {
            Layout
        }
    };
</script>
<style>
    #app {
        width: 100%;
        height: 100%;
    }
</style>
